import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Home from '../../modules/Home/Home';
import Post from '../../types/Post';

interface Frontmatter {
  frontmatter: Post;
}

interface PageContext {
  category: string;
  page: number;
}

interface Props {
  allMarkdownRemark: {
    nodes: Array<Frontmatter>;
    pageInfo: {
      totalCount: number;
    };
  };
}

const Category: React.FC<PageProps<Props>> = ({ data, pageContext }) => {
  const currentPage = (pageContext as PageContext).page || 1;
  const currentCategory = (pageContext as PageContext).category;

  const {
    allMarkdownRemark: {
      nodes: posts,
      pageInfo: { totalCount },
    },
  } = data;

  return (
    <Home
      posts={posts.map((post) => post.frontmatter)}
      currentCategory={currentCategory === '*' ? 'ALL POSTS' : currentCategory}
      currentPage={currentPage}
      totalPosts={totalCount}
    />
  );
};

export const query = graphql`
  query GetBlogPostsByCategory($category: String!, $limit: Int = 6, $exclude: String = " ") {
    allMarkdownRemark(
      filter: {
        frontmatter: { categories: { glob: $category, nin: [$exclude] }, title: { glob: "*" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
    ) {
      ...PostsNodes
    }
  }
`;

export default Category;
